@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700;800&family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,700&family=Inter:wght@500&family=Lato&family=Lobster&family=Nunito:wght@300;400;500;700;800&family=Open+Sans:wght@300;600&family=Poppins:ital,wght@0,100;0,500;1,500&family=Roboto:wght@300&family=Rubik:wght@400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Be Vietnam Pro', sans-serif;
}

:root {
  --primary-color: #416139;
  --secondary-color: #070919;
  --white-color: #fff;
  --black-color: #000;
  --black-gray-color: #3b3e66;
  --green-color: #68974b;
  --green-white-color: #cdd8b6;
  --dark-green-color: #5b6857;
  --darker-green-color: #43633a;
  --red-color: red;
}

html {
}

body {
  text-rendering: optimizeSpeed;
}

input {
  border: 0;
  outline: none;
}
a {
  text-align: none;
}
