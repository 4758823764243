button {
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 122px;
  background-color: var(--white-color);
  border: 1px solid;
  padding: 10px 7px;
  gap: 8px;
  border-radius: 8px;
  transition: 0.5s ease;
  &:hover {
    transform: translateY(-2px);
  }
}
