.content {
  padding-top: 74px;
  height: 1000px;
  .introduce {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .introduce-main {
      padding: 34px 34px 34px 169px;
      background-color: var(--primary-color);
    }
    .first {
      .introduce-heading {
        color: var(--white-color);
        font-size: 20px;
        font-weight: 600;
        padding-right: 70px;
        line-height: 30px;
        display: block;
        margin-bottom: 10px;
      }
      .introduce-view {
        text-transform: uppercase;
        color: var(--white-color);
      }
      .introduce-search {
        margin-top: 74px;
        position: relative;
        background-color: var(--white-color);
        border-radius: 8px;
        padding: 4px;
        max-width: 472px;
        display: flex;
        align-items: flex-end;
        margin-bottom: 24px;
        svg {
          position: absolute;
          color: #ccc;
          top: 50%;
          transform: translateY(-50%);
          left: 10px;
        }
        input {
          height: 40px;
          font-size: 16px;
          padding-left: 30px;
          caret-color: #ccc;
          &::placeholder {
            color: #ccc;
          }
        }
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          background-color: var(--green-color);
          cursor: pointer;
          font-size: 16px;
          color: var(--white-color);
          border-radius: 5px;
          height: 39px;
          width: 115px;
          padding: 5px 10px;
          transition: 0.5s ease;
          margin-left: auto;
          &:hover {
            transform: translateY(-4px);
          }
        }
      }
      .introduce-error {
        color: var(--red-color);
        margin-bottom: 16px;
      }
    }
    .introduce-img {
      background-color: var(--primary-color);
      .img-inner {
        width: 100%;
        transform: translateY(4px);
        .img-main {
          position: relative;
          margin-top: 50px;
          .introduce-scan {
            position: absolute;
            left: 60px;
            top: -50px;
            background: linear-gradient(
              180deg,
              var(--green-color),
              var(--primary-color)
            );
            width: 130px;
            height: 130px;
            border-radius: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            .scan-inner {
              border-radius: 24px;
              background-color: var(--white-color);
              width: 110px;
              height: 110px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 90px;
                height: 90px;
              }
            }
          }
        }
        .img-main {
          width: 100%;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  .product {
    width: 100%;
    padding-top: 50px;
    .inner {
      width: 1012px;
      margin: 0 auto;

      .product-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          font-size: 26px;
          font-weight: 700;
          line-height: 39px;
          color: var(--black-gray-color);
        }
        .product-tabs {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 10px;
        }
      }
    }
    .product-list {
      margin-top: 30px;
      .inner {
        width: 1012px;
        .slick-prev {
          &::before {
            font-family: 'slick';
            font-size: 20px;
            line-height: 1;
            opacity: 0.75;
            color: red;
            -webkit-font-smoothing: antialiased;
          }
        }
      }
      .product-item {
        cursor: pointer;
        padding: 0 5px;
        .product-img {
          width: 97px;
          height: 100px;
          overflow: hidden;
          border-radius: 7px;
          &:hover img {
            transform: scale(1.2);
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.5s ease;
          }
        }
        .product-name {
          color: var(--black-color);
          font-size: 12px;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box !important;
          font-weight: 700;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) and (min-width: 740px) {
  .content {
    .introduce {
      .introduce-main {
        padding: 127px 34px 83px 34px;
      }
      .first {
        .introduce-heading {
          font-size: 26px;
          padding-right: 0;
          line-height: 44px;
          margin-bottom: 31px;
        }
        .introduce-view {
          font-size: 26px;
        }
        .introduce-search {
          margin-top: 74px;
          position: relative;
          background-color: var(--white-color);
          border-radius: 8px;
          padding: 4px;
          max-width: 472px;
          display: flex;
          align-items: flex-end;
          margin-bottom: 24px;
          svg {
            position: absolute;
            color: #ccc;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
          }
          input {
            height: 48px;
            font-size: 18px;
            &::placeholder {
              color: #ccc;
            }
          }
          button {
            font-size: 21px;
            height: 47px;
            &:hover {
              transform: translateY(-4px);
            }
          }
        }
        .introduce-error {
          margin-left: 10px;
          font-size: 20px;
        }
      }
      .introduce-img {
        background-color: var(--primary-color);
        .img-inner {
          width: 100%;
          transform: translateY(0);
          .img-main {
            position: relative;
            margin-top: 200px;
          }
          .img-main {
            width: 100%;
            img {
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

// mobile header
@media screen and (max-width: 749px) {
  .content {
    .introduce {
      display: block;
      grid-template-columns: none;
      .introduce-main {
        padding: 52px 35px 100px;
      }
      .first {
        .introduce-heading {
          padding-right: 0;
          line-height: 34px;
          margin-bottom: 34px;
        }
        .introduce-view {
        }
        .introduce-search {
          border-radius: 13px;
          margin-bottom: 16px;
          svg {
            position: absolute;
            color: #ccc;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
          }
          input {
            height: 40px;
            font-size: 16px;
            padding-left: 30px;
            caret-color: #ccc;
            &::placeholder {
              color: #ccc;
            }
          }
          button {
            background-color: var(--green-color);
            cursor: pointer;
            font-size: 16px;
            color: var(--white-color);
            border-radius: 5px;
            height: 39px;
            width: 115px;
            padding: 5px 10px;
            transition: 0.5s ease;
            margin-left: auto;
            &:hover {
              transform: translateY(-4px);
            }
          }
        }
        .introduce-error {
          color: var(--red-color);
          margin-bottom: 16px;
        }
      }
      .introduce-img {
        background-color: var(--primary-color);
        .img-inner {
          width: 100%;
          transform: translateY(4px);
          .img-main {
            position: relative;
            margin-top: 0;
            .introduce-scan {
              position: absolute;
              left: 60px;
              top: -50px;
              background: linear-gradient(
                180deg,
                var(--green-color),
                var(--primary-color)
              );
              width: 130px;
              height: 130px;
              border-radius: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
              .scan-inner {
                border-radius: 24px;
                background-color: var(--white-color);
                width: 110px;
                height: 110px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  width: 90px;
                  height: 90px;
                }
              }
            }
          }
          .img-main {
            width: 100%;
            img {
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
