.wrapper {
  padding: 94px 0 0 0;
  margin: 0;
  background-color: #fdfaf6;
  .inner {
    background-color: var(--white-colors);
    max-width: 1012px;
    margin: 0 auto;
    // padding: 16px;
    padding-top: 1%;
    .info {
      display: flex;
      flex-direction: row;
      
      .info-img {
        width: 40%;
      }

    }
    .info-right{
      width: 60%;
      margin-left: 15px;
      display: flex;
      flex-direction: column;
    }
    .tensp{
      color: #3b3e66;
      font-weight: bold 700;
    }
    .div_tong{
      display: flex;
      flex-direction: row;
    }
    .left_trai{
      width: 15%;
    }
    .left_phai{
      margin-left: 5%;
      width: 55%;
    }
  }
}
