.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 74px;
  z-index: 9999;
  background-color: var(--white-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
  .inner {
    width: calc(100% - 120px * 2);
    height: 100%;
    padding: 0 105px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    .logo {
      width: 111px;
      cursor: pointer;

      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .translate {
      cursor: pointer;
      position: relative;
      &:hover .dropdown {
        display: block;
        animation: dropdown 0.5s ease;
      }
      .translate-img {
        width: 35px;
      }
      .dropdown {
        position: absolute;
        display: none;
        top: 38px;
        right: -15px;
        padding: 16px;
        background-color: var(--white-color);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
        border-radius: 8px;
        ul {
          list-style: none;
          li {
            opacity: 1;
            transition: 0.5s ease;
            &:hover {
              opacity: 0.6;
            }
          }
          li:nth-child(1) {
            position: relative;
            ::after {
              content: '';
              position: absolute;
              background-color: #ccc;
              width: 100%;
              height: 1px;
              bottom: 0;
              left: 0;
            }
            line-height: 40px;
            display: flex;
            align-items: center;
            img {
              width: 17px;
              margin-right: 5px;
            }
          }
          li:nth-child(2) {
            position: relative;
            line-height: 40px;
            display: flex;
            align-items: center;
            ::after {
              content: '';
              position: absolute;
              background-color: #ccc;
              width: 100%;
              height: 1px;
              bottom: 0;
              left: 0;
            }
            img {
              width: 17px;
              margin-right: 5px;
            }
          }
          li:nth-child(3) {
            line-height: 40px;
          }
          li:nth-child(4) {
            cursor: pointer;
            select {
              padding: 3px 6px;
              border-radius: 6px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@keyframes dropdown {
  0% {
    right: -100px;
    opacity: 0;
  }
  50% {
    opacity: 1;
    right: 10px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 740px) {
  .wrapper {
    height: 94px;
    .inner {
      width: calc(100% - 80px * 2);
      padding: 0 55px;
      .logo {
        width: 154px;
        cursor: pointer;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .translate {
        cursor: pointer;
        position: relative;
        &:hover .dropdown {
          display: block;
          animation: dropdown 0.5s ease;
        }
        .translate-img {
          width: 54px;
        }
        .dropdown {
          position: absolute;
          display: none;
          top: 71px;
          right: -15px;
          width: 270px;
          padding: 16px;
          background-color: var(--white-color);
          box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
          border-radius: 8px;
          ul {
            list-style: none;
            li {
              opacity: 1;
              transition: 0.5s ease;
              &:hover {
                opacity: 0.6;
              }
            }
            li:nth-child(1) {
              ::after {
                width: 100%;
                height: 2px;
                bottom: 0;
                left: 0;
              }
              line-height: 49px;
              display: flex;
              align-items: center;
              img {
                width: 17px;
                margin-right: 5px;
              }
              span {
                font-size: 21px;
              }
            }
            li:nth-child(2) {
              position: relative;
              line-height: 49px;
              display: flex;
              align-items: center;
              ::after {
                content: '';
                position: absolute;
                background-color: #ccc;
                width: 100%;
                height: 2px;
                bottom: 0;
                left: 0;
              }
              img {
                width: 17px;
                margin-right: 5px;
              }
              span {
                font-size: 21px;
              }
            }
            li:nth-child(3) {
              line-height: 40px;
              font-size: 21px;
            }
            li:nth-child(4) {
              cursor: pointer;
              select {
                padding: 3px 8px;
                border-radius: 26px;
                cursor: pointer;
                font-size: 21px;
              }
            }
          }
        }
      }
    }
  }
}

// mobile header
@media screen and (max-width: 749px) {
  .wrapper {
    .inner {
      width: calc(100% - 20px * 2);
      height: 100%;
      padding: 0 45px;
      .logo {
        img {
        }
      }
      .translate {
        &:hover .dropdown {
          display: block;
          animation: dropdown 0.5s ease;
        }
        .translate-img {
          width: 35px;
        }
        .dropdown {
          position: absolute;
          display: none;
          top: 38px;
          right: -15px;
          padding: 16px;
          background-color: var(--white-color);
          box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
          border-radius: 8px;
          ul {
            list-style: none;
            li {
              opacity: 1;
              transition: 0.5s ease;
              &:hover {
                opacity: 0.6;
              }
            }
            li:nth-child(1) {
              position: relative;
              ::after {
                content: '';
                position: absolute;
                background-color: #ccc;
                width: 100%;
                height: 1px;
                bottom: 0;
                left: 0;
              }
              line-height: 40px;
              display: flex;
              align-items: center;
              img {
                width: 17px;
                margin-right: 5px;
              }
            }
            li:nth-child(2) {
              position: relative;
              line-height: 40px;
              display: flex;
              align-items: center;
              ::after {
                content: '';
                position: absolute;
                background-color: #ccc;
                width: 100%;
                height: 1px;
                bottom: 0;
                left: 0;
              }
              img {
                width: 17px;
                margin-right: 5px;
              }
            }
            li:nth-child(3) {
              line-height: 40px;
            }
            li:nth-child(4) {
              cursor: pointer;
              select {
                padding: 3px 6px;
                border-radius: 6px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
